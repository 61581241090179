import jsSHA from "jssha"; //See: https://caligatio.github.io/jsSHA/

export function calculatePasswordHash(salt: string, password: string): string {
  const hashFunction = new jsSHA("SHA-512", "TEXT");
  if (salt) {
    hashFunction.update(salt);
  }
  if (password) {
    hashFunction.update(password);
  }
  return hashFunction.getHash("HEX");
}
